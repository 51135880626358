import React from "react"
import { Link } from "gatsby"
import Helmet from "react-helmet"

import Navbar from "./navbar"
import Footer from "./footer"

export default ({ children }) => {
  return (
    <React.Fragment>
      <Helmet>
        <title>Documentation | Developer Portal | Liquid Group Pte. Ltd.</title>
        <link
          href="https://fonts.googleapis.com/css?family=Open+Sans"
          rel="stylesheet"
        />
      </Helmet>
      <Navbar />
      <div id="master-container">
        <div id="documentation" className="container">
          <div id="doc-sidebar-parent">
            <div id="doc-sidebar" className="scrollable nounderline">
              <h4>Overview</h4>
              <ul>
                <li>
                  <Link to="/docs/introduction">Introduction</Link>
                </li>
                <li>
                  <Link to="/docs/getting-started">Getting Started</Link>
                </li>
              </ul>
              <h4>Topic</h4>
              <ul>
                <li>
                  <Link to="/docs/metadata">Metadata</Link>
                </li>
                <li>
                  <Link to="/docs/product-types">Product Types</Link>
                </li>
                <li>
                  <Link to="/docs/response-code">Response Code</Link>
                </li>
                <li>
                  <Link to="/docs/signature-mechanism">
                    Signature Mechanism
                  </Link>
                </li>
              </ul>
              <h4>Mobile SDK</h4>
              <ul>
                <li>
                  <Link to="/docs/ios-sdk">iOS SDK</Link>
                </li>
                <li>
                  <Link to="/docs/android-sdk">Android SDK</Link>
                </li>
              </ul>
              {/* <h4>Resources</h4>
              <ul>
                <li>
                  <Link to="/docs/restful-libraries">RESTful Libraries</Link>
                </li>
              </ul> */}
              <h4>Common Object</h4>
              <ul>
                <li>
                  <Link to="/docs/fee-object">Fee Object</Link>
                </li>
                <li>
                  <Link to="/docs/payee-object">Payee Object</Link>
                </li>
                <li>
                  <Link to="/docs/payer-object">Payer Object</Link>
                </li>
                <li>
                  <Link to="/docs/qr-object">QR Object</Link>
                </li>
                <li>
                  <Link to="/docs/source-object">Source Object</Link>
                </li>
              </ul>
            </div>
          </div>
          <div id="doc-content-parent">
            <div id="doc-content">{children}</div>
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  )
}
