import React from "react"

export default () => {
  return (
    <footer className="footer">
      <div className="container p-10">
        <div className="row">
          <div className="col-md-3">
            <h4>Company</h4>
            <ul className="list-unstyled">
              <li className="mb-3">
                <a href="https://www.liquidpaygroup.com">Liquid Group</a>
              </li>
              <li className="mb-3">
                <a href="https://www.liquidpay.com">Liquid Pay</a>
              </li>
            </ul>
          </div>
          <div className="col-md-3">
            <h4>Resources</h4>
            <ul className="list-unstyled">
              <li className="mb-3">
                <a href="/docs/introduction">Documentation</a>
              </li>
              <li className="mb-3">
                <a href="/apis">API References</a>
              </li>
              <li className="mb-3">
                <a href="/mobile-sdk">Mobile SDK</a>
              </li>
            </ul>
          </div>
          <div className="col-md-3">
            <h4>Support</h4>
            <ul className="list-unstyled">
              <li className="mb-3">
                <a href="/support/contact-us">Contact Us</a>
              </li>
              <li className="mb-3">
                <a href="/support/faq">FAQs</a>
              </li>
            </ul>
          </div>
          <div className="col-md-3">
            <h4>Legal</h4>
            <ul className="list-unstyled">
              <li className="mb-3">
                <a href="/legal/term-of-use">Term of Use</a>
              </li>
              <li className="mb-3">
                <a href="/legal/privacy-policy">Privacy Policy</a>
              </li>
            </ul>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-md-8">
            Copyright © {new Date().getFullYear()} Liquid Group Pte Ltd. All
            rights reserved
          </div>
        </div>
      </div>
    </footer>
  )
}
