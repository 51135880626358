import React from "react"
import { Link } from "gatsby"

import Logo from "../../../static/images/logo.png"

export default () => {
  return (
    <header className="bg-white" style={{ borderBottom: "1px solid #dde0e2" }}>
      <div className="container">
        <nav id="navbar" className="navbar navbar-expand-lg">
          <Link to="/" className="navbar-brand">
            <img src={Logo} alt="Liquid Pay Developer Portal" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto">
              <li className="nav-item active">
                <Link to="/docs/introduction" className="nav-link">
                  Documentation
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/apis" className="nav-link">
                  API Explorer
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/tools" className="nav-link">
                  Tools
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/support" className="nav-link">
                  Support
                </Link>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </header>
  )
}
